<template>
  <div >
    <v-container style="position: relative" >
  <swiper class="swipercat px-4" :options="swiperOption">

    <swiper-slide v-for="cat in cats" :key="cat.id">

      <div v-if="!pageloaded" class="d-flex justify-center">
        <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>

      <div v-else @click="selectitem(cat.id)" style="height: 100%;width: 100%;cursor: pointer" class="d-flex flex-column justify-space-between align-center">
        <img class="mt-6" :alt="cat.title" :title="cat.title" :src="$store.state.thumbnail + cat.icon" height="50%"/>
        <p class="primary--text ma-0 mb-5">{{ cat.title }}</p>
      </div>

    </swiper-slide>
  </swiper>
      <div class="swiper-button-prev swiper-button-white arrowsize-r" slot="button-prev"></div>
      <div class="swiper-button-next swiper-button-white arrowsize-l" slot="button-next"></div>
    </v-container>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {EffectFade} from 'swiper';
import {Navigation} from 'swiper';
import axios from "axios";
SwiperCore.use([Navigation]);
SwiperCore.use([EffectFade]);
export default {
  name: 'App',

  data() {
    return {
      swiperOption: {
        slidesPerView: 5 ,
        spaceBetween: 50 ,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1125: {
            slidesPerView: 5,
            spaceBetween: 50
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 50
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 50
          },
          450: {
            slidesPerView: 2,
            spaceBetween: 50
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 50
          },

          300: {
            slidesPerView: 1,
            spaceBetween: 50
          }
        },
        navigation: {
          nextEl: '.swiper-button-prev',
          prevEl: '.swiper-button-next'
        }
      },
      cats : ['','','','',''],
      pageloaded : false,
    }
  },
  components : {
    Swiper,
    SwiperSlide,

  }
  ,created() {
    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;
      this.pageloaded=true;
    })
  },
  methods : {
    selectitem(id){
      this.$router.push({name:'cats',query:{cat:id}});
    }
  }
};
</script>

<style lang="scss" scoped>

.swipercat {
  height: 175px;
  width: 100%;
  padding: 2px 0 ;


  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 18px;
    color: white;
    overflow: hidden;
    background-color: #f9f9f9;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.1);
    border-radius: 14px;
    position: relative;
  }
}

.activecat {
  background-color: #e1e1e1;
}


</style>


<style lang="scss" scoped>


@import "../assets/css/loading";


.arrowsize-r {

  left : 0 !important;
  color: #570654 !important;
  --swiper-navigation-size: 20px !important;
}

.arrowsize-l {

  right : 0 !important;
  color: #570654 !important;
  --swiper-navigation-size: 20px !important;
}

.swiper-button-prev{
  background-image: url(../assets/image/Main/arrow-left.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next{
  background-image: url(../assets/image/Main/arrow-right.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.swiper-button-next::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "next";
}

.swiper-button-prev::after {
  display: none;
  font-family: 'swiper-icons' !important;
  font-weight: 1000;
  content: "prev";
}

</style>