<template>
    <div style="background-color: #ffdbf5" class="sliderMain">
        <v-skeleton-loader v-if="!items" class="swipersite" type="image"></v-skeleton-loader>

        <swiper v-if="items" class="swipersite" :options="swiperOption">
            <swiper-slide v-for="(item,index) in items" :key="index">

                <div style="width: 100%">
                    <router-link v-if="item.target" :to="'/'+item.target">
                        <v-img

                                height="100%"
                                :lazy-src="$store.state.thumbnail+item.image"
                                :src="$store.state.upload+item.image"
                        >
                        </v-img>
                    </router-link>
                    <v-img
                            v-else
                            height="100%"
                            :lazy-src="$store.state.thumbnail+item.image"
                            :src="$store.state.upload+item.image"
                    >
                    </v-img>

                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>

        </swiper>

    </div>
</template>

<script>

    import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
    import SwiperCore, {Pagination, EffectFade, Autoplay} from 'swiper/core';

    SwiperCore.use([Pagination, EffectFade, Autoplay]);
    import axios from "axios";

    export default {
        name: 'App',
        data() {
            return {

                items: null,
                swiperOption: {
                    spaceBetween: 10,
                    effect: 'fade',
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                }
            }
        },
        components: {
            Swiper,
            SwiperSlide,
        },

        created() {

            if (!this.$store.state.slider) {
                axios.get(this.$store.state.api + 'slidersite').then(response => {
                    this.items = response.data.data;
                    this.$store.state.slider = this.items;
                })
            } else {
                this.items = this.$store.state.slider;
            }

        },
        methods: {}
    };
</script>


<style lang="scss">

    .sliderMain {
        min-height: 15vw;
    }

    .v-skeleton-loader__image {

        height: 100% !important;
    }

    .mheight {
        min-height: 35vw;
    }

    .slidecursor {
        cursor: pointer;
    }

    @import "../assets/css/slidersite";

    /*.swiper-slide{
      background-color: #2e2e2e !important;
    }*/

    .swiper-pagination-bullet-active {
        background-color: #891587 !important;
    }

</style>
