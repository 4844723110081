<template>
  <div @keydown.enter="search">
    <transition enter-active-class="animate__animated animate__fadeIn" appear>
      <div>

        <SliderSite></SliderSite>

    <div class="categories py-12">
      <h1 class="swipertitle font-weight-regular">دسته‌بندی صنایع</h1>

      <div class="catssearch mb-5">
      <v-text-field
          label="عبارت مورد نظر خود را جستجو کنید ..."
          style="width: 100%;border-radius: 8px;"
          dark
          solo
          v-model="str"
          height="58"
          hide-details
      >
        <template v-slot:append>
          <div class="mt-2" style="cursor: pointer;" @click="search">
            <img style="filter: drop-shadow(0px 2px 2px #000);" src="../assets/image/Main/serach-icon.png" width="80%" />
          </div>
        </template>
      </v-text-field>
      </div>



      <div class="catssearchprp flex-wrap flex-sm-nowrap">
        <v-select
            label="انتخاب دسته بندی"
            style="width: 100%;border-radius: 8px;"
            class="ma-1"
            v-model="selectedCatId"
            :items="cats"
            solo
            item-text="title"
            item-value="id"
            color="#f4f4f4"
            height="45"
            hide-details
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
          </template>
          <template v-slot:no-data>
            <div class="d-flex justify-center">
              <p class="ma-0 my-2">در حال بارگزاری</p>
            </div>
          </template>
        </v-select>
        <v-select
            label="استان"
            style="width: 100%;border-radius: 8px;"
            solo
            v-model="selectedprovinceId"
            :items="provinces"
            class="ma-1"
            item-text="name"
            item-value="id"
            color="#f4f4f4"
            height="45"
            hide-details
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
          </template>
          <template v-slot:no-data>
            <div class="d-flex justify-center">
              <p class="ma-0 my-2">در حال بارگزاری</p>
            </div>
          </template>
        </v-select>
        <v-select
            label="شهر"
            style="width: 100%;border-radius: 8px;"
            solo
            v-model="selectedcityId"
            :items="cities"
            class="ma-1"
            item-text="name"
            item-value="id"
            color="#f4f4f4"
            height="45"
            hide-details
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
          </template>
          <template v-slot:no-data>
            <div class="d-flex justify-center">
              <p class="ma-0 my-2">ابتدا استان را انتخاب کنید</p>
            </div>
          </template>
        </v-select>
      </div>
      <CatsSwiper></CatsSwiper>
      <router-link :to="{name:'cats'}" custom v-slot="{navigate,href}" >
      <v-btn
          class="btn d-block mx-auto my-3"
          height="40"
          width="170"
          style="padding-top: 11px !important;"
          @click="navigate"
          :href="href"
          role="link"
      >
        همه شرکت ها در یک نگاه
      </v-btn>
      </router-link>


    </div>

    <v-img  style="position: relative;" width="100%" src="../assets/image/Main/body.jpg">
      <template v-slot:default>
        <div class="toprightex">
          <p class="extitle mb-3">نمـایشـگاه مجازی</p>
          <p class="exsubtitle mb-sm-8 mb-4">هر شرکت ، یک غرفه در نمایشگاه مجازی ایران آی سیب</p>
          <router-link to="/ex" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
          <v-btn
              class="btn"
              @click="navigate"
              role="link"
          >
            ورود به نمایشگاه
          </v-btn>
          </router-link>

        </div>
      </template>
    </v-img>
    <v-container class="d-flex flex-wrap">
      <div  class="col-md-6 pl-md-10 pr-md-0">
        <h2 class="primary--text mt-4 mt-sm-11 mb-0 contenttitle font-weight-regular">اخبار و رویدادهای مهم</h2>
        <p class="mb-11 subtitle">اطلاع از آخرین اخبار و رویدادهای ایران آی سیب</p>
        <div class="content">
          <div v-for="(item,i) in news" :key="item.id" >
          <div class="d-flex flex-column flex-sm-row align-center">
            <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
            <a @click="navigate" :href="href" role="link" style="width: 100%;max-width: 200px">
            <img v-if="item.image" :src="$store.state.thumbnail+item.image" height="140" width="100%" style="max-width: 200px">
            </a>
            </router-link>
            <div class="contentitem mr-6">
              <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
              <p class="mt-3 mb-1 defaultcolor" style="font-size: 14px;cursor: pointer" ><a class="textlinkhover" @click="navigate" :href="href" role="link" >{{ item.title }}</a></p>
              </router-link>
              <p class="mb-2 contenttime">{{item.createTime | momentDateAndTime}}</p>
              <p class="mb-1 contentdesc">{{ item.desc.substring(0,70)+".." }}</p>
              <router-link :to="{name:'singlenews',params:{id:item.id,title:item.title}}" custom v-slot="{navigate,href}">
              <p style="cursor: pointer" class="mb-0 defaultcolor morecontent">
                <a class="textlinkhover" @click="navigate" :href="href" role="link">ادامه مطلب</a></p>
              </router-link>
            </div>
          </div>
          <v-divider v-if="i!=2" class="my-2"></v-divider>
          </div>

        </div>
        <router-link to="/news" custom v-slot="{navigate,href}" >
        <p class="ml-2 mb-0 mt-5 defaultcolor morecontent" style="font-size: 16px;cursor: pointer">
          <a class="textlinkhover" :href="href" @click="navigate" role="link">مشاهده بیشتر >></a></p>
        </router-link>
      </div>
      <div  class="col-md-6 pr-md-10 pl-md-0">
        <h2 class="primary--text mt-4 mt-sm-11 mb-0 contenttitle font-weight-regular">حامیان ما در ایران آی سیب</h2>
        <p class="mb-5 subtitle">اتحادیه ها، تشکل ها ، انجمن ها ، اتاق های
          بازرگانی و خانه های صنعت معدن وتجارت حامی ایران آی سیب</p>
        <div class="content  d-flex flex-wrap  justify-space-around align-center">
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (4).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (5).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (9).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (1).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (7).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (8).png" width="80%"/>

          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (6).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (3).png" width="80%"/>
          </div>
          <div class="sponrs-img">
            <img src="../assets/image/Main/Sponsors/s (2).png" width="80%"/>
          </div>

        </div>
        <p class="ml-2 mb-0 mt-5 primary--text morecontent" style="font-size: 16px">مشاهده بیشتر >></p>
      </div>
    </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import CatsSwiper from "@/components/CatsSwiper";
import SliderSite from "@/components/SliderSite";
import axios from "axios";
import moment from "jalali-moment";
export default {
  name: 'App',
  filters : {
    momentDateAndTime(date){
      return moment.unix(date).locale('fa').format('HH:mm - jYYYY/jMM/jDD');
    },
  },
  metaInfo() {
    return {
      link: [{rel: 'canonical', href: this.$store.state.url}]
    }
  },
  data() {
    return {

      str : '',
      cats:[],
      selectedCatId : null,
      selectedprovinceId : null,
      selectedcityId : null,
      provinces : [],
      cities : [],
      news : [],
    }
  },
  components: {
    CatsSwiper,
    SliderSite,
  },
  methods : {
    search(){
      this.$router.push({name:'cats',query:{q : this.str,cat:this.selectedCatId,province:this.selectedprovinceId,city:this.selectedcityId}});
    }
  },
  watch : {
    selectedprovinceId(newval , oldval){
      if(newval!==oldval){
        this.cities = [];
        axios.get(this.$store.state.api + 'getcity',{params : {id:this.selectedprovinceId}}).then(response => {
          this.cities = response.data.data;
        })
      }
    },
  },
  created() {
    axios.get(this.$store.state.api + 'getprovince').then(response => {
      this.provinces = response.data.data;
    })
    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;
    })

    axios.get(this.$store.state.api + 'news').then(response => {
      let data = response.data.data;
      for (let i=0 ; i < 3;i++){
        if(data[i]){
          this.news.push(data[i]);
        }
      }
    })

  }
};
</script>

<style lang="scss">


.catssearchprp {
  display: flex;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 18px;
}

.catssearchprp .v-label{
  color: #570654 !important;
  font-size: 16px ;
  height: 50px !important;
}

.catssearchprp .v-input{
  color: #570654 !important;
  font-size: 16px !important;
}

.catssearchprp .v-input__slot {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  padding: 0 16px !important;
}

.catssearchprp .theme--light.v-input input, .theme--light.v-input textarea{
  color: #570654 !important;
}

.catssearchprp .v-list * , ::before , ::after {
  color: #570654 !important;
}

.catssearchprp .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f0f0f0;
}

.catssearchprp .v-input__slot{
  background-color: #f0f0f0 !important;
}

.catssearch{
  display: block;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 38px;
}

.catssearch .v-input__slot{
  background: #570654 !important;
}

.catssearch .v-label{
  color: #fff !important;
  font-size: 18px ;
  height: 40px !important;
}

.catssearch .v-input{
  color: #fff !important;
  font-size: 18px !important;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6) !important;
}

.catssearch .v-input__slot {
  box-shadow: none !important;
  padding: 0 20px !important;
}

.centeredtitle {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.maintitle {
  font-size: 5.622vw;
  min-width: 300px;
  user-select: none;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.6);
}

.mainsubtitle {
  font-size: 2.811vw;
  min-width: 300px;
  user-select: none;
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 6px 5px rgba(0, 0, 0, 0.6);
}

@media only screen and (max-width: 768px) {

  .maintitle {
    font-size: 38px !important;
    line-height: 35px;
  }

  .mainsubtitle {
    font-size: 18px !important;
    line-height: 30px;
  }

  .extitle {
    font-size: 38px !important;
    line-height: 35px;
  }

  .exsubtitle {
    font-size: 14px !important;
    line-height: 15px;
  }
}

.centeredsearch {
  width: 636px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Shabnam FD" !important;
}

@media only screen and (max-width: 768px) {
  .centeredsearch {
    width: 95%;
  }

  .centeredsearch .v-label {
    font-size: 14px !important;
  }

}

.centeredsearch .v-input__slot {

  background: rgba(255, 255, 255, 0.24) !important;


}

.centeredsearch .v-label {
  color: #fff !important;
  font-size: 24px;
  height: 50px !important;
}

.centeredsearch .v-input {
  color: #fff !important;
  font-size: 24px !important;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6) !important;
}

.centeredsearch .v-input__slot {
  box-shadow: none !important;
  padding: 0 16px !important;
}

.extitle {
  font-size: 4.216vw;
  user-select: none;
  color: #ffffff;
  text-align: right;
  text-shadow: 0px 5px 4px rgba(0, 0, 0, 0.6);
}

.exsubtitle {
  font-size: 16px;
  user-select: none;
  color: #cbcbcb;
  text-align: right;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6);
}

.toprightex {
  position: absolute;
  top: 17%;
  right: 6%;
}

.subtitle {
  margin-top: 1px;
  font-size: 16px;
  letter-spacing: 0.6px;
  color: #aaaaaa;
}

.content {
  min-height: 518px;
  padding: 30px;
  background-color: #fcfcfc;
  border-radius: 15px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
}

.contenttitle {
  font-size: 24px;
  letter-spacing: 0.5px;
}

.contenttime {
  font-size: 11px;
  color: #696969;
}

.contentdesc {
  font-size: 12px;
  text-align: justify;
  text-align-last: right;
  color: #aaaaaa;
}

.contentitem {
  /*max-width: 278px;*/
  width: 100%;
  min-height: 140px;
}

.morecontent {
  text-align: left;
  font-size: 12px;
}

.swipertitle {
  text-align: center;
  color: #570654;
  font-size: 28px;
  margin-bottom: 38px;
}

@media only screen and (max-width: 768px) {
  .swipertitle {
    font-size: 24px;
  }
}

.sponrs-img {
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}




</style>
